<template>
  <div class="columns is-centered" v-if="infosSalon !== null">
    <div class="column is-4-desktop is-7-tablet is-12-mobile">
      <article class="message is-success">
        <div class="message-header">
          <p style="font-weight: normal;">
            {{ $t('sketchy.global.rejoin.texte_rejoin', [infosSalon.nom_salon]) }}
          </p>
          <button class="delete" aria-label="delete" @click="clear"></button>
        </div>
        <div class="message-body has-text-centered" style="padding-top: 6px;padding-bottom: 9px;">
          <button class="button is-success is-small" @click="$emit('rejoin')">
            {{ $t('sketchy.global.rejoin.bouton_rejoin') }}
          </button>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationRejoin',

  data() {
    return {
      infosSalon: null,
    };
  },

  created() {
    // Si l'id de salon n'a pas bien été nettoyé des cookies
    // alors on vérifie si il existe encore
    console.info('this.$session =', this.$session);
    console.info('this.$session._last_salon =', this.$session.data._last_salon);

    if (this.$session.data._last_salon !== undefined) {
      console.info('Id salon présent dans session => ', this.$session.data._last_salon)

      this.$axios.get(this.$url_serveur + '/salon/' + this.$session.data._last_salon + '?existe')
        .then((resp) => {
          console.info('ID SALON EXISTE ENCORE =>', resp);

          this.infosSalon = resp.data;
        })
        .catch((err) => {
          if (err.response.status === 410 /*GONE*/) {
            this.$session._last_salon = undefined;
            this.$session._last_pseudo = undefined;
            this.$session._last_password = undefined;
          }
        });
    }
  },

  methods: {
    hide() {
      this.infosSalon = null;
    },

    clear() {
      this.infosSalon = null;

      this.$session._last_salon = undefined;
      this.$session._last_pseudo = undefined;
      this.$session._last_password = undefined;
    },
  }
}
</script>
